import { Body, PageLayout } from 'components/common';
import BottomSheet, { BottomSheetRef } from 'components/common/BottomSheet';
import Button from 'components/common/Button';
import Icon from 'components/common/Icon';
// import Icon from 'components/common/Icon';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const TimeBuffFilter: React.FC = () => {
  const [selectedGender, setSelectedGender] = useState<'female' | 'male'>();
  const [open, setOpen] = useState<boolean>();
  const [history, setHistory] = useState<[]>([]);
  const ref = useRef<BottomSheetRef>(null);

  const handleClickFilter = (gender: string) => {
    if (gender === 'female' || gender === 'male') {
      setSelectedGender(gender);
    }
  };

  useLayoutEffect(() => {
    imagePreload();
  }, []);

  useEffect(() => {
    setOpen(true);
    if (mysetting.preferGender !== 'both') {
      setSelectedGender(mysetting.preferGender);
    } else {
      setSelectedGender(mysetting.preferGender === 'male' ? 'female' : 'male');
    }

    if (LiteDataManager.getState() > 0) {
      const hMainContainer = document.getElementById('hMainContainer');
      if (hMainContainer) {
        CommonUI.showToast(hMainContainer, { text: brsc.time_buff_toast_1, duration: 2000, position: 'bottom' });
      }
    }
  }, []);

  function imagePreload() {
    const sources = [
      '/imgweb/sale/infinity_silver.png',
      '/imgweb/sale/time_buff_female_active.svg',
      '/imgweb/sale/time_buff_female_inactive.svg',
      '/imgweb/sale/time_buff_male_active.svg',
      '/imgweb/sale/time_buff_male_inactive.svg',
    ];

    sources.forEach((src) => {
      const image = new Image();
      image.src = src;
    });
  }

  const hide = (reset = false) => {
    return new Promise((res) => {
      setOpen(false);
      setTimeout(() => {
        res(reset ? Transition.resetToHome() : Transition.back());
      }, 500);
    });
  };

  const swipe = async () => {
    try {
      if (!selectedGender) {
        return;
      }

      if (!TimeBuffManager.getIsTimeBuff()) {
        await TimeBuffManager.deduct();
      }
      MainUI.changePreferGender(selectedGender);
      hide(true).then((_) => {
        FindUI.find();
      });
    } catch (err) {
      // TO-DO: 에러 로그 보내기
      BLog.err(err);
    }
  };

  const showHistory = async () => {
    const result = await ItemDataManager.getItemHistoryByCategory('timeBuff');
    setHistory(result);
    ref.current?.show();
    CommonUI.addReactBackButtonCallback(ref.current?.hide);
  };

  if (TimeBuffManager.getIsTimeBuff()) {
    return (
      <PageLayout overlay onClick={hide}>
        <Body
          className="rounded-t-lg overflow-y-scroll"
          backgroundColor="transparent"
          overlay
          onClose={hide}
          style={{
            background: 'linear-gradient(93deg, #FFADCF 0%, #FF4390 50%, #FF4390 100%)',
            transition: 'all 0.2s',
            transform: open ? 'unset' : 'translateY(100%)',
            height: 'fit-content',
            top: 'unset',
            paddingBottom: `${(Device.name === 'iOS' ? MainUI.getNotchValue().navHeight : 0) + 16}px`,
            bottom: 0,
          }}
        >
          <div className="text-white flex flex-col items-center justify-center px-4">
            <div id="hTimeFilterCountDown" className="text-title2 py-4 h-16"></div>
            <div className="text-title3 pb-2">{brsc.gender_please}</div>
            <div className="flex flex-row w-full gap-2 my-6">
              <TimeBuffFilterItem value="female" onClick={handleClickFilter} selected={selectedGender === 'female'} />
              <TimeBuffFilterItem value="male" onClick={handleClickFilter} selected={selectedGender === 'male'} />
            </div>
            <Button className="w-full" style={{ text: brsc.btn_region_popup, color: 'white', fontColor: 'text-700' }} onClick={swipe} />
          </div>
        </Body>
      </PageLayout>
    );
  }

  return (
    <PageLayout overlay onClick={() => hide()}>
      <Body
        className="rounded-t-lg overflow-y-scroll"
        backgroundColor="transparent"
        overlay
        onClose={hide}
        style={{
          background: 'linear-gradient(116deg, #FFADCF 1.5%, #FF4390 50.75%, #FF4390 100%)',
          transition: 'all 0.2s',
          transform: open ? 'unset' : 'translateY(100%)',
          height: 'fit-content',
          top: 'unset',
          paddingBottom: `${(Device.name === 'iOS' ? MainUI.getNotchValue().navHeight : 0) + 16}px`,
          bottom: 0,
        }}
      >
        {open && <Icon name="scene/ic_guideline.svg" className="absolute right-3 z-10 top-3" onClick={showHistory} size={36} />}
        <div className="py-8 px-12 text-center">
          <img className="w-3/4" src="/imgweb/sale/infinity_silver.png" alt="" />
        </div>
        <div className="px-8 pb-2 text-title3 text-white text-center">{`${brsc.time_buff}: ${brsc.count_item.replace('$param', ItemDataManager.getItemCount('timeBuff'))}`}</div>
        <div className="flex flex-column align-center px-4 w-full"></div>
        <div className="flex px-4 flex-column align-center justify-center text-center text-white">
          <div className="text-4">
            {brsc.expiration_date}:{' '}
            {ItemDataManager.getItemsByCategory('timeBuff').length > 0
              ? `${TimeUtil.getYYMMDDFromDate(ItemDataManager.getItemsByCategory('timeBuff')[0].createdTime)}~${TimeUtil.getYYMMDDFromDate(
                  ItemDataManager.getItemsByCategory('timeBuff')[0].expireAt
                )}`
              : ''}
          </div>
          <div className="text-title3 pt-9 pb-4">{brsc.gender_please}</div>
          <div className="flex flex-row w-full gap-2 my-6">
            <TimeBuffFilterItem value="female" onClick={handleClickFilter} selected={selectedGender === 'female'} />
            <TimeBuffFilterItem value="male" onClick={handleClickFilter} selected={selectedGender === 'male'} />
          </div>
          <Button className="w-full" style={{ text: brsc.btn_region_popup, color: 'white', fontColor: 'text-700' }} onClick={swipe} />
          <Button className="w-full mt-2" style={{ text: brsc.later, fontColor: 'white', type: 'text' }} onClick={() => hide(false)} />
        </div>
      </Body>
      <BottomSheet ref={ref} className="h-[60%]">
        <div className="text-title3 text-text-700 text-center py-6">{brsc.activity_log}</div>
        <div className="flex w-full flex-col flex-auto overflow-y-auto">
          {history.length > 0 &&
            history.map((item: any) => (
              <div key={item._id} className="flex flex-row items-center justify-between w-full py-2">
                <div className="text-text-700 text-title4">{`${TimeUtil.getYYMMDDFromDate(item.usedAt)} / ${TimeUtil.getHHMMSSFromDate(item.usedAt)}`}</div>
                <div className="text-text-300 text3">{brsc.item_usage_completed}</div>
              </div>
            ))}
          {history.length === 0 && <div className="flex flex-auto items-center text-center text-text-700 justify-center">no list</div>}
        </div>
        <Button onClick={() => ref.current?.hide()} className="mt-4" style={{ text: brsc.close, color: 'gray-400', fontColor: 'text-700' }} />
      </BottomSheet>
    </PageLayout>
  );
};

export default TimeBuffFilter;

interface TimeBuffFilterItemProps {
  value: string;
  onClick: (value: string) => void;
  selected: boolean;
}

const TimeBuffFilterItem: React.FC<TimeBuffFilterItemProps> = ({ value, onClick, selected }) => {
  return (
    <div
      onClick={() => onClick(value)}
      className="bg-white flex flex-column align-center justify-center rounded-xl border-solid border-4 flex-1 p-4"
      style={{ borderColor: selected ? '#FF4391' : '#FFFFFF' }}
    >
      <div className="text-title3 text-text-700">{value === 'male' ? brsc.male : brsc.female}</div>
      <div className="text3 text-white py-0.5 px-2 rounded-md" style={{ backgroundColor: '#FF4391', visibility: selected ? 'visible' : 'hidden' }}>
        FREE
      </div>
      <img className="mt-3" src={`/imgweb/sale/time_buff_${value}_${selected ? 'active' : 'inactive'}.svg`} alt="" />
    </div>
  );
};
